import { Logout } from '@mui/icons-material'
import DashboardIcon from '@mui/icons-material/Dashboard'

export const paths = [
  {
    index: 1,
    title: 'Reportería de producto',
    path: '/',
    icon: (
      <DashboardIcon sx={{ color: '#fafafa' }} />
    ),

  },
   {
     index: 2,
     title: 'Reportería de servicio',
     path: '/reporte-servicio',
     icon: (
       <DashboardIcon sx={{ color: '#fafafa' }} />
     )
   },
  {
    index: 3,
    title: 'Cerrar sesión',
    path: '/iniciar-sesion',
    icon: (
      <Logout sx={{ color: '#fafafa' }} />
    )
  }
];


