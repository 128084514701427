import {
  Box,
  Button,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useContext, useState } from 'react'
import { HomeContext } from '../context/HomeContext'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import Logo from '../assets/images/logo-nest.png'

const Login = () => {

  const navigate = useNavigate()
  const [auth] = useContext(HomeContext)
  const [flag, setFlag] = useState(true)
  const [error, setError] = useState(false)
  const [user, setUser] = useState({
    email: '',
    password: '',
  })


  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    })

    if (user.email && user.password) {
      setFlag(false)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    // Comprobar si las credenciales ingresadas son iguales a las que están almadenadas
    if (user.email !== auth.email || user.password !== auth.password) {
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error al iniciar sesión',
        text: 'Las credenciales de acceso son incorrectas',
      })
      setError(true)
    } else {
      // Si las credenciales ingresadas son las mismas almacenadas en el context, las guardamos en el localstorage
      // y redireccionamos a la página del dashboard
      localStorage.setItem('email', auth.email)
      localStorage.setItem('password', auth.password)

      navigate('/')
      Swal.fire({
        icon: 'success',
        title: '¡Bienvenido, Mario!',
        text: 'En este DEMO podrás encontrar dos distintos tipos de reportes que son fundamentales y que pueden emplearse en cualquier organización: reportes de productos y de servicios',
        footer: '<a target="_blank" href="https://nestconsultores.cl/">Visita nuestro sitio web</a>'
      })
    }


  }

  return (
    <>

      <Box
        sx={{
          backgroundColor: 'background.paper',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%'
          }}
        >
          <div>
            <Stack
              spacing={1}
              sx={{ mb: 3 }}
            >
              <Typography variant="h4">
                Iniciar sesión
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
              >
                Debes solicitar una cuenta en caso de no tenerla
                &nbsp;

              </Typography>
            </Stack>

            <form
              onSubmit={handleSubmit}
            >
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Correo electrónico"
                  name="email"
                  onChange={handleInputChange}
                  type="email"
                  error={error}
                />
                <TextField
                  fullWidth
                  label="Contraseña"
                  name="password"
                  type="password"
                  onChange={handleInputChange}
                  error={error}

                />
              </Stack>

              <Button
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                disabled={flag}
                style={{ backgroundColor: '#ff735c' }}
              >
                Iniciar sesión
              </Button>
            </form>
            <img src={Logo} alt="Nest Consultores" style={{ width: '100%' }} />
          </div>
        </Box>
      </Box>
    </>
  )
}



export default Login
