import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { useEffect  } from 'react'
import DashboardDetails from '../components/DashboardDetails'
import { useNavigate } from 'react-router-dom'
import { textServices } from '../utils/text'

const ServicesDashboard = () => {
  const navigate = useNavigate()
  const link = "https://app.powerbi.com/view?r=eyJrIjoiOThlNzZlNDAtZDk4ZC00YTUxLWFkYWMtZmE2ZjExMzAwMmU1IiwidCI6ImQwZDljYzdlLTc3MzQtNGRjYS1hODZjLThlOTg3ZDhhOTQzYSJ9&pageName=ReportSectionca95510c2ebb9e266607"

  useEffect(() => {
    if (!localStorage.getItem('email') || !localStorage.getItem('password')) {
      navigate('/iniciar-sesion')
    } 
    // else {
    //   Swal.fire({
    //     icon: 'success',
    //     title: '¡Bienvenido, Mario!',
    //     text: 'Los reportes de productos sirven para analizar la información de ventas, inventario y comportamiento del cliente, con el fin de tomar decisiones estratégicas y mejorar la rentabilidad de un negocio. ',
    //     footer: '<a target="_blank" href="https://nestconsultores.cl/">Visita nuestro sitio web</a>'
    //   })
    // }
  }, [navigate])


  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}

    >
      <Container >
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">
              Reporte de servicios
            </Typography>
            <Box
              sx={{
                backgroundColor: '#FF735C',
                padding: '4px',
                textAlign: 'center',
                border: '1px solid',
                borderRadius: '5px',
                my: '5px'
              }}
            >
              <Typography
                color="white"
                variant="body2"
              >
                &nbsp;
                {textServices}
              </Typography>
            </Box>

          </div>
          <div>
            <Grid container xs={12} item={true}>
              <DashboardDetails link = {link} />
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default ServicesDashboard