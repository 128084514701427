import Login from './pages/Login'
import Footer from "./components/Footer"
import { Navigate, Route, Routes } from 'react-router-dom'
import MainDrawer from "./components/MainDrawer"
import Home from './pages/Home'
import { useContext } from 'react'
import { HomeContext, HomeProvider } from './context/HomeContext'
import ServicesDashboard from './pages/ServicesDashboard'


const AppRouting = () => {

  const [auth, setAuth] = useContext(HomeContext)


  return (
    <>
      <HomeProvider value={[auth, setAuth]}>
        <Routes>
          <Route exact path="/iniciar-sesion" element={<Login />} />
          <Route element={<MainDrawer />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/reporte-servicio" element={<ServicesDashboard />} />
          </Route>
          <Route
            path="*"
            element={<Navigate to="/iniciar-sesion" replace />}
          />
        </Routes>
      </HomeProvider>

      <Footer />
    </>
  )
}

export default AppRouting